<template>
  <div>
    <a-card
      :title="'企业ETC车辆: ' + carNmbr + '辆办理成功'"
      :bordered="false"
    ></a-card>
    <a-card :bordered="false" style="margin-top: 10px">
      <div class="bms-flex-space-between">
        <a-form-model layout="inline">
          <a-form-model-item label="车牌号">
            <a-input v-model="plateNumber" placeholder="请输入车牌" />
          </a-form-model-item>
          <a-form-model-item style="margin-left: 15px">
            <a-button type="primary" @click="onQuery">
              {{ "查询" }}
            </a-button>
          </a-form-model-item>
        </a-form-model>
        <a-button type="primary" @click="download"> 导出</a-button>
      </div>
    </a-card>
    <a-card :bordered="false" style="margin-top: 10px">
      <a-table
        :locale="emptyText"
        :columns="columns"
        :data-source="dataObj"
        :pagination="pagination"
        @change="changeTabel"
        rowKey="index"
      >
        <a slot="name" slot-scope="text, record" @click="showModal(record)">{{
          text
        }}</a>
      </a-table>
    </a-card>
    <!-- 弹出层 -->
    <a-modal
      :visible="visible"
      title="车辆详情"
      okText="确定"
      cancelText="取消"
      @cancel="handleCancel"
      @ok="handleCancel"
      width="54vw"
    >
      <div class="popup">
        <div class="popup-bolk">企业信息</div>
        <ul v-for="(item, i) in etcDetailArrCompany" :key="i">
          <li>
            <h4>{{ item.key }}：</h4>
            <div>
              <h5>{{ item.value }}</h5>
            </div>
          </li>
        </ul>
        <div class="popup-bolk">车辆信息</div>
        <ul
          v-for="(item, i) in etcDetailArrCar"
          :key="i"
          :class="
            item.key === '行驶证背面' ||
            item.key === '行驶证正面' ||
            item.key === '道路运输许可证'
              ? 'for-img'
              : ''
          "
        >
          <li
            v-if="
              item.key === '行驶证背面' ||
              item.key === '行驶证正面' ||
              item.key === '道路运输许可证'
            "
          >
            <h4>{{ item.key }}：</h4>
            <viewer class="viewer">
              <img class="viewer-img" :src="item.value" style="" />
            </viewer>
          </li>
          <li v-else>
            <h4>{{ item.key }}：</h4>
            <div>
              <h5>{{ item.value }}</h5>
            </div>
          </li>
        </ul>
      </div>
    </a-modal>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl, getToken } from "@/utils/request/request";

const columns = [
  {
    title: "车牌号",
    dataIndex: "plateNumber",
    key: "plateNumber",
  },
  {
    title: "车牌颜色",
    dataIndex: "color",
    key: "color",
  },
  {
    title: "ETC状态",
    dataIndex: "status",
    key: "status",
  },

  {
    title: "订单完成时间",
    dataIndex: "finishTime",
    key: "finishTime",
  },
  {
    title: "ETC卡号",
    dataIndex: "etcNo",
    key: "etcNo",
  },
  {
    title: "OBU号",
    dataIndex: "obuNo",
    key: "obuNo",
  },
  {
    title: "车辆信息",
    dataIndex: "vehicle",
    key: "vehicle",
    scopedSlots: { customRender: "name" },
  },
];
export default {
  name: "index",
  data() {
    return {
      emptyText: { emptyText: "暂无数据" },
      carNmbr: "0", //已办理车辆数
      queryStale: false, //是否点击了查询
      visible: false,
      columns,
      pagination: false,
      searchObj: {
        page: 1,
        pageSize: 10,
      },
      plateNumber: "",
      dataObj: [],
      paging: {
        current: 1,
        defaultPageSize: 10,
      },
      etcDetailArrCompany: [], //企业信息
      etcDetailArrCar: [], //车辆信息
      detailsObj: null,
    };
  },
  methods: {
    //获取车辆列表
    start(e) {
      if (!e) {
        e = "";
      }
      let self = this;
      this.$http
        .getV(
          "/depositCompany/findCar/lists?page=" +
            self.searchObj.page +
            "&pageSize=" +
            self.searchObj.pageSize +
            "&plateNumber=" +
            e
        )
        .then((res) => {
          if (res.code === 200) {
            if (res.data.rows) {
              res.data.rows.forEach((item) => {
                item["vehicle"] = "车辆信息";
              });
              self.dataObj = res.data.rows;
              self.pagination = { total: parseInt(res.data.total), current: self.searchObj.page };
            }
          }
        });
    },
    //点击翻页
    changeTabel(e) {
      console.log(e);
      this.searchObj.page = e.current;
      this.searchObj.pageSize = e.pageSize;
      this.start();
    },
    //点击查询
    onQuery() {
      this.searchObj.page = 1;
      this.searchObj.pageSize = 10;
      this.getCarNmbr();
      this.start(this.plateNumber);
    },
    //点击下载
    download() {
      let obj = {
        page: this.searchObj.page,
        pageSize: this.searchObj.pageSize,
        plateNumber: this.plateNumber,
      };
      this.$http
        .downLoadExcel("/depositCompany/findCar/lists/exportExcel", obj)
        .then((res) => {
          console.log(res);
        });
    },
    //获取已办理所有车辆
    getCarNmbr() {
      let self = this;
      axios
        .get(baseUrl + "/depositCompany/findCar/listnums", {
          headers: {
            "x-access-token": getToken(),
          },
        })
        .then((res) => {
          if (res.data.code === 200) {
            self.carNmbr = res.data.data;
          }
        });
    },
    //点击展开详情
    showModal(e) {
      let self = this;
      this.visible = true;
      console.log(e.id);
      this.$http.getV("/depositCompany/findCar?id=" + e.id).then((res) => {
        if (res.code === 200) {
          self.detailsObj = res.data;
          let detail = res.data;
          //企业信息
          self.etcDetailArrCompany = [
            { key: "企业名称", value: detail.etcCompany.companyName },
            { key: "经办人", value: detail.etcCompany.agentName },
            { key: "经办人证件号码", value: detail.etcCompany.agentIdCard },
            { key: "经办人电话", value: detail.etcCompany.agentPhoneNum },
          ];
          //车辆信息
          self.etcDetailArrCar = [
            { key: "车辆所有人", value: detail.trucks.owner },
            { key: "车牌号", value: detail.trucks.plateNumber },
            { key: "ETC卡号", value: detail.truckEtcOrder.etcNo },
            { key: "车牌颜色", value: detail.trucks.plateColor },
            { key: "车长(mm)", value: detail.trucks.vehLength },
            { key: "车宽(mm)", value: detail.trucks.vehWidth },
            { key: "车高(mm)", value: detail.trucks.vehHeight },
            { key: "车轮数(个)", value: detail.trucks.wheelCount },
            { key: "轴数(个)", value: detail.trucks.vehicleAxles },
            { key: "车辆识别代号", value: detail.trucks.vin },
            { key: "发动机号", value: detail.trucks.engineNo },
            {
              key: "是否为集装箱",
              value:
                detail.trucks.isContainer == 0
                  ? "否"
                  : detail.trucks.isContainer == 1
                  ? "是"
                  : detail.trucks.isContainer == 2
                  ? "是"
                  : "",
            },
            { key: "品牌型号", value: detail.trucks.brand },
            { key: "车辆类型", value: detail.truckEtcOrderExt.ocrVehicleType }, //
            {
              key: "使用性质",
              value:
                detail.trucks.useType == 0
                  ? "非营运(普通车)"
                  : detail.trucks.useType == 1
                  ? "营运(普通车)"
                  : detail.trucks.useType == 7
                  ? "营运(挂车)"
                  : detail.trucks.useType == 9
                  ? "营运(挂车)"
                  : detail.trucks.useType == 10
                  ? "营运(挂车)"
                  : "",
            },
            {
              key: "经营范围",
              value:
                detail.trucks.cardUserType == 0
                  ? "普通车"
                  : detail.trucks.cardUserType == 24
                  ? "单用途集装箱车"
                  : detail.trucks.cardUserType == 26
                  ? "专用号牌车"
                  : detail.trucks.cardUserType == 27
                  ? "牵引车"
                  : detail.trucks.cardUserType == 28
                  ? "多用途集装箱车"
                  : "专项作业车专用",
            },
            { key: "总质量(kg)", value: detail.trucks.totalMass },
            { key: "整备质量(kg)", value: detail.trucks.maintenanceMass },
            { key: "注册日期", value: detail.trucks.registerDate },
            { key: "发证日期", value: detail.trucks.issueDate },
            { key: "道路运输许可证", value: detail.trucks.transLicenseUrl },
            { key: "行驶证正面", value: detail.trucks.plateUrlUp },
            { key: "行驶证背面", value: detail.trucks.plateUrlDown },
          ];
        }
      });
    },
    //关闭详情
    handleCancel() {
      this.visible = false;
    },
  },
  created() {
    this.start();
    this.getCarNmbr();
  },
};
</script>

<style scoped>
.popup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  height: 64vh;
  padding: 20px;
  overflow: auto;
  overflow-y: auto;
}

.popup > ul {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.popup > ul > li {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup > ul > li > h4 {
  width: 100px;
  text-align: right;
}

.popup > ul > li > div {
  width: 240px;
  height: 36px;
  background-color: rgb(238, 238, 238);
  margin-left: 10px;
  display: flex;
  padding-top: 8px;
  word-break: break-all;
  overflow-y: auto;
}

.popup > ul > li > div > h5 {
  margin: 0;
  padding: 0;
  margin-left: 10px;
}

.popup-bolk {
  margin: 20px;
  width: 100% !important;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 600;
}

.for-img {
  max-width: 100% !important;
  height: auto !important;
}

.viewer {
  overflow: hidden;
  width: 240px !important;
  height: 140px !important;
  background-color: rgb(255, 255, 255) !important;
}

.for-img > li {
  height: 140px !important;
}

.for-img > li > div {
  background-color: rgb(255, 255, 255);
}

.viewer-img {
  max-width: 100% !important;
  height: auto !important;
  display: inline-block;
}
</style>